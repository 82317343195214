import { createSlice } from '@reduxjs/toolkit'

export const STEPS = {
  DATA_CAR: 'dataCar',
  COLOR_MILEAGE: 'colorMileage',
  DATA_OFFER: 'dataOffer',
}

export const EXPEDIENT_STEPS = {
  VEHICLE_INFORMATION: 'vehicleInformation',
  CURRENT_OWNER: 'currentOwner',
  PREVIUS_OWNER: 'previusOwner',
}

export const DIG_EXPEDIENT_STEPS = {
  OFICIAL_ID: 'oficial_id',
  CIRCULATION_CARD: 'circulation_card',
  INVOICE: 'invoice',
}

const defaultValuesForm = {
  color: 'DEFAULT',
  make: 'Marca',
  mileage: '',
  version: 'Versión',
  versionShortName: 'Versión',
  requested_price: 'Propuesta $$',
  model: 'Modelo',
  year: 'Año',
  report: '',
  recovered: '',
  invoices: '',
  registration: '',
}

const initialState = {
  form: {},
  personalInformation: {},
  offers: [],
  officeSelected: null,
  reportId: null,
  currentStep: EXPEDIENT_STEPS.VEHICLE_INFORMATION,
  steps: {
    [EXPEDIENT_STEPS.VEHICLE_INFORMATION]: {
      isCompleted: false,
      isExpanded: true,
      isValidForm: false,
      isCompleteForm: false,
    },
    [EXPEDIENT_STEPS.CURRENT_OWNER]: {
      isCompleted: false,
      isExpanded: false,
      isValidForm: false,
      isCompleteForm: false,
    },
    [EXPEDIENT_STEPS.PREVIUS_OWNER]: {
      isCompleted: false,
      isExpanded: false,
      isValidForm: false,
      isCompleteForm: false,
    },
  },
  dig_exp_steps: {
    [DIG_EXPEDIENT_STEPS.OFICIAL_ID]: {
      isCompleted: false,
      isFrontCompleted: false,
      isBackCompleted: false,
    },
    [DIG_EXPEDIENT_STEPS.CIRCULATION_CARD]: {
      isCompleted: false,
      isFrontCompleted: false,
      isBackCompleted: false,
    },
    [DIG_EXPEDIENT_STEPS.INVOICE]: {
      isCompleted: false,
      isFrontCompleted: false,
      isBackCompleted: false,
    },
  },
  leadType: 'clikauto',
  report:
    typeof window !== 'undefined'
      ? JSON.parse(window.sessionStorage.getItem('report'))
      : null,
  easyReport:
    typeof window !== 'undefined'
      ? JSON.parse(window.sessionStorage.getItem('easyReport'))
      : null,
  offer: {
    type: null,
    place: null,
  },
  expedient: null,
  leadId: null,
  scheduleForm: {},
  promoCode: null,
  discountAmount: null,
  finishedRecord: false,
  currentOwner: {},
  previusOwner: {},
  sendAutoInformation: 0,
  expId: null,
  token: null,
}

const quoteSlice = createSlice({
  name: 'appraisal',
  initialState,
  reducers: {
    setValue: (state, action) => {
      const { key, value, persist } = action.payload
      if (persist) {
        window.sessionStorage.setItem(
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        )
      }
      state[key] = value
    },
    setFormValue: (state, action) => {
      const { key, value } = action.payload
      state.form[key] = value
    },
    setFormDefaultValues: (state) => {
      state.form = {
        ...defaultValuesForm,
      }
    },

    setOfferValue: (state, action) => {
      const { key, value } = action.payload
      state.offer[key] = value
    },

    setFormValues: (state, action) => {
      const values = action.payload
      Object.keys(values).forEach((k) => {
        state.form[k] = values[k]
      })
    },

    setPersonalInformationValue: (state, action) => {
      const { key, value } = action?.payload
      state.personalInformation[key] = value
    },

    setPersonalInformationValues: (state, action) => {
      const values = action.payload
      Object.keys(values).forEach((k) => {
        state.personalInformation[k] = values[k]
      })
    },

    setReport: (state, action) => {
      state.report = action.payload
    },

    resetForm: (state) => {
      state.form = initialState.form
      state.report = null
      state.easyReport = null
      state.personalInformation = initialState.personalInformation
      state.steps = initialState.steps
      state.scheduleForm = initialState.scheduleForm
      state.currentOwner = initialState.currentOwner
      state.previusOwner = initialState.previusOwner
      state.leadType = initialState.leadType
    },

    resetExpedient: (state) => {
      state.step = initialState.step
      state.currentOwner = initialState.currentOwner
      state.previusOwner = initialState.previusOwner
    },

    goBackForm: (state, action) => {
      const { step } = action.payload
      if (step === 'mileage') delete state.form.requested_price
      delete state.form[step]
      state.report = null
      state.easyReport = null
    },

    changeExpanded: (state, action) => {
      const { step, value } = action.payload
      const isExpanded = value
      state.currentStep = step
      state.steps[step].isExpanded = isExpanded
    },

    toggleExpanded: (state, action) => {
      state.steps[EXPEDIENT_STEPS.VEHICLE_INFORMATION].isExpanded = false
      state.steps[EXPEDIENT_STEPS.CURRENT_OWNER].isExpanded = false
      state.steps[EXPEDIENT_STEPS.PREVIUS_OWNER].isExpanded = false
      const { step } = action.payload
      const isExpanded = !state.steps[step].isExpanded
      state.currentStep = step
      state.steps[step].isExpanded = isExpanded
    },

    changeValidForm: (state, action) => {
      const { step, value } = action.payload
      state.steps[step].isValidForm = value
    },

    changeCompleteForm: (state, action) => {
      const { step, value } = action.payload
      state.steps[step].isCompleteForm = value
    },

    changeStatus: (state, action) => {
      const { step, value } = action.payload
      state.steps[step].isCompleted = value
    },

    // change status of digital expedient
    changeFrontStep: (state, action) => {
      const { step, value } = action.payload
      state.dig_exp_steps[step].isFrontCompleted = value
    },

    changeBackStep: (state, action) => {
      const { step, value } = action.payload
      state.dig_exp_steps[step].isBackCompleted = value
    },

    changeCompleteStep: (state, action) => {
      const { step, value } = action.payload
      state.dig_exp_steps[step].isCompleted = value
    },

    setScheduleFormValue: (state, action) => {
      const { key, value } = action.payload
      state.scheduleForm[key] = value
    },

    resetScheduleForm: (state) => {
      state.scheduleForm = initialState.scheduleForm
    },

    setPromoCode: (state, action) => {
      const value = action.payload
      state.promoCode = value
    },

    resetPromoCode: (state) => {
      state.promoCode = initialState.promoCode
    },

    setDiscountAmount: (state, action) => {
      const value = action.payload
      state.discountAmount = value
    },

    resetDiscountAmount: (state) => {
      state.discountAmount = initialState.discountAmount
    },

    setExpedientValue: (state, action) => {
      const { key, value } = action.payload
      state.expedient[key] = value
    },
    setExpedientValues: (state, action) => {
      const values = action.payload
      Object.keys(values).forEach((k) => {
        state.expedient[k] = values[k]
      })
    },
    setCurrentOwnerValue: (state, action) => {
      const { key, value } = action.payload
      state.currentOwner[key] = value
    },
    setPreviusOwnerValue: (state, action) => {
      const { key, value } = action.payload
      state.previusOwner[key] = value
    },

    sendAutoInformationForm: (state) => {
      state.sendAutoInformation += 1
    },
  },
})

export default quoteSlice.reducer
export const {
  setFormValue,
  setFormValues,
  setOfferValue,
  setOffers,
  resetForm,
  goBackForm,
  setValue,
  changeExpanded,
  toggleExpanded,
  setFormDefaultValues,
  changeStatus,
  changeFrontStep,
  changeBackStep,
  changeCompleteStep,
  setPersonalInformationValue,
  setPersonalInformationValues,
  setScheduleFormValue,
  resetScheduleForm,
  setPromoCode,
  resetPromoCode,
  setDiscountAmount,
  resetDiscountAmount,
  setExpedientValue,
  setExpedientValues,
  changeValidForm,
  changeCompleteForm,
  setCurrentOwnerValue,
  setPreviusOwnerValue,
  sendAutoInformationForm,
} = quoteSlice.actions
