import { combineReducers } from 'redux'
import commonReducer from 'redux/modules/common'
import searchReducer from 'redux/modules/search'
import appraisalReducer from 'redux/modules/appraisal'
import checkoutReducer from 'redux/modules/checkout'
import easyReducer from 'redux/modules/easy'
import auctionReducer from 'redux/modules/auction'

export default combineReducers({
  common: commonReducer,
  search: searchReducer,
  appraisal: appraisalReducer,
  checkout: checkoutReducer,
  easy: easyReducer,
  auction: auctionReducer,
})
