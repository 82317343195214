import { createSlice } from '@reduxjs/toolkit'
import {
  TOPBAR_CHECKOUT_STEPS, TOPBAR_BUY_EXPEDIENT_STEPS, CHECKOUT_STEPS, BUY_EXPEDIENT_STEPS,
} from 'enums'

const initialState = {
  currentStep: TOPBAR_CHECKOUT_STEPS.personalInformation,
  form: {},
  steps: {
    [CHECKOUT_STEPS.PERSONAL_INFORMATION]: {
      isCompleted: false,
      isExpanded: true,
    },
    [CHECKOUT_STEPS.EXTRAS]: {
      isCompleted: false,
      isExpanded: false,
    },
    [CHECKOUT_STEPS.QUOTER_CAR]: {
      isCompleted: false,
      isExpanded: false,
    },
    [CHECKOUT_STEPS.PAYMENT]: {
      isCompleted: false,
      isExpanded: false,
    },
  },
  currentStepBuyExpedient: TOPBAR_BUY_EXPEDIENT_STEPS.personalDataStep,
  buyExpedientSteps: {
    [BUY_EXPEDIENT_STEPS.PERSONAL_DATA]: {
      isCompleted: false,
      isExpanded: true,
      isValidForm: false,
      isCompleteForm: false,
    },
    [BUY_EXPEDIENT_STEPS.DOCUMENTS]: {
      isCompleted: false,
      isExpanded: false,
      isValidForm: false,
      isCompleteForm: false,
    },
    [BUY_EXPEDIENT_STEPS.INVOICE]: {
      isCompleted: false,
      isExpanded: false,
      isValidForm: false,
      isCompleteForm: false,
    },
  },
  vehicle: {},
  personalInformation: {},
  extras: [],
  quoterCar: {},
  payment: {
    debit_information: {},
    credit_information: {},
  },
  hasInterestedInExtras: true,
  hasInterestedInQuoterCar: true,
  vehicleOffer: {},
  documents: {},
  invoice: {},
  personalData: 0,
  resetQuoteCar: 0,
}

const quoteSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setValue: (state, action) => {
      const { key, value, persist } = action.payload
      if (persist) {
        window.sessionStorage.setItem(
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        )
      }
      state[key] = value
    },
    resetForm: (state) => {
      state.personalInformation = initialState.personalInformation
      state.extras = initialState.extras
      state.quoterCar = initialState.quoterCar
      state.payment = initialState.payment
      state.steps = initialState.steps
      state.form = initialState.form
      state.vehicle = initialState.vehicle
      state.vehicleOffer = initialState.vehicleOffer
      state.hasInterestedInExtras = initialState.hasInterestedInExtras
    },
    resetVehicle: (state) => {
      state.vehicle = initialState.vehicle
    },
    changeExpanded: (state, action) => {
      const { step, value } = action.payload
      const isExpanded = value
      state.currentStep = TOPBAR_CHECKOUT_STEPS[step]
      state.steps[step].isExpanded = isExpanded
    },
    toggleExpanded: (state, action) => {
      state.steps = Object.keys(state.steps).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...state.steps[key],
            isExpanded: false,
          },
        }),
        {},
      )
      const { step } = action.payload
      const isExpanded = !state.steps[step].isExpanded
      state.currentStep = TOPBAR_CHECKOUT_STEPS[step]
      state.steps[step].isExpanded = isExpanded
    },
    changeStatus: (state, action) => {
      const { step, value } = action.payload
      state.steps[step].isCompleted = value
    },
    setQuoterCarValue: (state, action) => {
      const { key, value } = action.payload
      state.quoterCar[key] = value
    },
    setQuoterCarValues: (state, action) => {
      const values = action.payload
      Object.keys(values).forEach((k) => {
        state.quoterCar[k] = values[k]
      })
    },
    setDebitPaymentValue: (state, action) => {
      const { key, value } = action.payload
      state.payment.credit_information = {}
      state.payment.debit_information[key] = value
    },
    setCreditPaymentValue: (state, action) => {
      const { key, value } = action.payload
      state.payment.debit_information = {}
      state.payment.credit_information[key] = value
    },
    toggleBuyExpedientExpanded: (state, action) => {
      state.buyExpedientSteps = Object.keys(state.buyExpedientSteps).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...state.buyExpedientSteps[key],
            isExpanded: false,
          },
        }),
        {},
      )
      const { step } = action.payload
      const isExpanded = !state.buyExpedientSteps[step].isExpanded
      state.currentStepBuyExpedient = TOPBAR_BUY_EXPEDIENT_STEPS[step]
      state.buyExpedientSteps[step].isExpanded = isExpanded
    },
    changeBuyExpedientStatus: (state, action) => {
      const { step, value } = action.payload
      state.buyExpedientSteps[step].isCompleted = value
    },
    changeValidForm: (state, action) => {
      const { step, value } = action.payload
      state.buyExpedientSteps[step].isValidForm = value
    },
    changeCompleteForm: (state, action) => {
      const { step, value } = action.payload
      state.buyExpedientSteps[step].isCompleteForm = value
    },
    setDocumentsValue: (state, action) => {
      const { key, value } = action.payload
      state.documents[key] = value
    },
    setInvoiceValue: (state, action) => {
      const { key, value } = action.payload
      state.invoice[key] = value
    },
    sendPersonalDataForm: (state) => {
      state.personalData += 1
    },
    resetQuoteCarForm: (state) => {
      state.quoterCar = {}
      state.resetQuoteCar += 1
    },
  },
})

export default quoteSlice.reducer
export const {
  setQuoterCarValue,
  setQuoterCarValues,
  setValue,
  resetForm,
  resetVehicle,
  changeExpanded,
  toggleExpanded,
  changeStatus,
  setCreditPaymentValue,
  setDebitPaymentValue,
  toggleBuyExpedientExpanded,
  changeBuyExpedientStatus,
  changeValidForm,
  changeCompleteForm,
  sendPersonalDataForm,
  setDocumentsValue,
  setInvoiceValue,
  resetQuoteCarForm,
} = quoteSlice.actions
