import { createSlice } from '@reduxjs/toolkit'
import { EASY_STEPS } from 'enums'

const initialState = {
  steps: {
    [EASY_STEPS.SELLER_INFORMATION_STEP]: {
      isCompleted: false,
      isExpanded: true,
      isValidForm: false,
      isCompleteForm: false,
    },
    [EASY_STEPS.CAR_INFORMATION_STEP]: {
      isCompleted: false,
      isExpanded: false,
      isValidForm: false,
      isCompleteForm: false,
    },
    [EASY_STEPS.SCHEDULE_STEP]: {
      isCompleted: false,
      isExpanded: false,
      isValidForm: false,
      isCompleteForm: false,
    },
  },
  sellerInformation: {},
  carInformation: {},
  scheduleInformation: {},
  leadId: null,
  sendScheduleData: 0,
}

const easySlice = createSlice({
  name: 'EASY',
  initialState,
  reducers: {
    setValue: (state, action) => {
      const { key, value, persist } = action.payload
      if (persist) {
        window.sessionStorage.setItem(
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        )
      }
      state[key] = value
    },

    resetForm: (state) => {
      state.sellerInformation = initialState.sellerInformation
      state.carInformation = initialState.carInformation
      state.scheduleInformation = initialState.scheduleInformation
      state.steps = initialState.steps
    },

    changeExpanded: (state, action) => {
      state.buyExpedientSteps = Object.keys(state.buyExpedientSteps).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...state.steps[key],
            isExpanded: false,
          },
        }), {},
      )
      const { step } = action.payload
      const isExpanded = !state.steps[step].isExpanded
      state.steps[step].isExpanded = isExpanded
    },

    toggleExpanded: (state, action) => {
      state.steps = Object.keys(state.steps).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...state.steps[key],
            isExpanded: false,
          },
        }),
        {},
      )
      const { step } = action.payload
      const isExpanded = !state.steps[step].isExpanded
      // state.currentStep = TOP_BAR_STEPS[step]
      state.steps[step].isExpanded = isExpanded
    },

    changeStatus: (state, action) => {
      const { step, value } = action.payload
      state.steps[step].isCompleted = value
    },

    changeValidForm: (state, action) => {
      const { step, value } = action.payload
      state.steps[step].isValidForm = value
    },

    changeCompleteForm: (state, action) => {
      const { step, value } = action.payload
      state.steps[step].isCompleteForm = value
    },

    sendScheduleForm: (state) => {
      state.sendScheduleData += 1
    },
  },
})

export default easySlice.reducer
export const {
  setQuoterCarValue,
  setQuoterCarValues,
  setValue,
  resetForm,
  resetVehicle,
  changeExpanded,
  toggleExpanded,
  changeStatus,
  changeValidForm,
  changeCompleteForm,
  sendScheduleForm,
} = easySlice.actions
