import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import reducer from 'redux/reducer'

const options = {
  reducer,
  preloadedState: {},
  devTools: process.env.NODE_ENV !== 'production',
}

if (process.env.NODE_ENV !== 'production') {
  options.middleware = (getDefaultMiddleware) => getDefaultMiddleware()
}

export const makeStore = () => configureStore(options)

export const wrapper = createWrapper(makeStore, {
  debug: process.env.NODE_ENV !== 'production',
  serializeState: (state) => JSON.stringify(state),
  deserializeState: (state) => JSON.parse(state),
})
