import EventEmitter from 'eventemitter3'

const eventEmitter = new EventEmitter()
const Emitter = {
  suscribe: (event, fn) => eventEmitter.on(event, fn),
  suscribeOnce: (event, fn) => eventEmitter.once(event, fn),
  unsubscribe: (event, fn) => eventEmitter.removeListener(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload),
  removeAllListeners: () => eventEmitter.removeAllListeners(),
}

Object.freeze(Emitter)
export default Emitter
