/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filters: null,
  bidSaved: {},
  allTypesSelected: false,
  totalVehicles: 0,
  resetFilter: 0,
  page: 1,
  auctionSelected: null,
  auctions: typeof window !== 'undefined'
    ? JSON.parse(window.sessionStorage.getItem('auctions') || '[]')
    : [],
}

const auctionSlice = createSlice({
  name: 'auctions',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { key, value } = action.payload
      state.filters[key] = value
    },
    setFilters: (state, action) => {
      const filters = action.payload
      if (state.filters) {
        Object.assign(state.filters, filters)
      } else {
        state.filters = filters
      }
    },
    setOverridedFilters: (state, action) => {
      const filters = action.payload
      state.filters = filters
    },

    resetFilters: (state) => {
      state.filters = {}
      state.resetFilter += 1
    },
    setAuctions: (state, action) => {
      const auctions = action.payload
      window.sessionStorage.setItem(
        'auctions',
        typeof auctions === 'object' ? JSON.stringify(auctions) : String(auctions),
      )
      state.auctions = auctions
    },
    reset: (state) => {
      state.auctions = []
    },
    setPage: (state, action) => {
      const page = action.payload
      state.page = page
    },
    setValue: (state, action) => {
      const { key, value, persist } = action.payload
      if (persist) {
        window.sessionStorage.setItem(
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        )
      }
      state[key] = value
    },
  },
})

export default auctionSlice.reducer
export const {
  setFilter,
  setFilters,
  setOverridedFilters,
  resetFilters,
  setAuctions,
  setPage,
  setValue,
  reset,
} = auctionSlice.actions
