export const LEAD_TYPE = Object.freeze({
  FINANCING: 'PAGINA FINANCIAMIENTO',
  CONTACT: 'contact',
  SELL_YOUR_CAR: 'COMPRAMOS TU AUTO',
  SELL_YOUR_CAR_EASY: 'COMPRAMOS TU AUTO - EASY',
  SELL_YOUR_CAR_WITH_INSPECTION: 'COMPRAMOS TU AUTO - CON CITA',
  SELL_YOUR_CAR_WITH_INSPECTION_EASY: 'COMPRAMOS TU AUTO EASY - CON CITA',
  EASY: 'VENDE TU AUTO - EASY',
  EASY_DEALERS: 'EASY - DEALERS',
  DEALERS_ONBOARDING: 'DEALERS - ONBOARDING',
})

export const INCOMING_TYPE = Object.freeze({
  ACCOUNT_STATEMENTS: 'ACCOUNT_STATEMENTS',
  PAYROLL_RECEIPTS: 'PAYROLL_RECEIPTS',
  NONE: 'NONE',
})

export const CREDIT_STATUS = Object.freeze({
  EXCELLENT: 'EXCELLENT',
  GOOD: 'GOOD',
  BAD: 'BAD',
  NONE: 'NONE',
})

export const VEHICLE_STATUS = Object.freeze({
  ARCHIVED: 'archived',
  ON_HOLD: 'hold',
  AVAILABLE: 'available',
  ONGOING: 'ongoing',
})

export const RESOURCES = Object.freeze({
  MAKER: 'MAKERS',
  MODEL: 'MODELS',
  VERSION: 'VERSIONS',
})

export const SCHEDULE_TYPES = Object.freeze({
  SUCURSAL: 'SUCURSAL',
  DOMICILIO: 'DOMICILIO',
  ASTROLLANTAS: 'ASTROLLANTAS',
})

export const INSPECTION_TYPE = Object.freeze({
  DOMICILIO: 'Domicilio',
  A_DOMICILIO: 'A Domicilio',
})

export const INSPECTION_POINTS = Object.freeze({
  SUCURSAL: 'sucursal',
  DOMICILIO: 'domicilio',
  ASTROLLANTAS: 'astrollantas',
})

export const TOPBAR_STEPS = Object.freeze({
  VEHILE_DATA: 'vehicleData',
  OFFERS: 'offers',
  INSPECTION: 'inspection',
})

export const FILE_TYPES = {
  CIRCULATION_CARD_FRONT: 'circulationCardFront',
  CIRCULATION_CARD_BACK: 'circulationCardBack',
  VEHICULAR_TENURE: 'vehicularTenure',
  INVOICE_FRONT: 'invoiceFront',
  INVOICE_BACK: 'invoiceBack',
  PERSONALID_FRONT: 'personIdFront',
  PERSONALID_BACK: 'personIdBack',
  OLD_INVOICE_FRONT: 'oldInvoiceFront',
  OLD_INVOICE_BACK: 'oldInvoiceBack',
  OLD_PERSONALID_FRONT: 'oldPersonIdFront',
  OLD_PERSONALID_BACK: 'oldPersonIdBack',
}
export const CHECKOUT_STEPS = {
  PERSONAL_INFORMATION: 'personalInformation',
  EXTRAS: 'extras',
  QUOTER_CAR: 'quoterCar',
  PAYMENT: 'payment',
}

export const CHECKOUT_RESOURCES = Object.freeze({
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  EXTRAS: 'EXTRAS',
  ORDER_DETAIL: 'ORDER_DETAIL',
})

export const DISCOUNT_TYPES = Object.freeze({
  HOT_SALE: 'hot_sale',
})

export const PAYMENTS_STATUS = Object.freeze({
  COMPLETED: 'COMPLETED',
  CHARGE_PENDING: 'CHARGE_PENDING',
})

export const TOPBAR_CHECKOUT_STEPS = {
  personalInformation: 'Datos',
  extras: 'Extras',
  quoterCar: 'Auto a cuenta',
  payment: 'Pago',
}

export const TOPBAR_BUY_EXPEDIENT_STEPS = {
  personalDataStep: 'Datos',
  documentsStep: 'Documentos',
  invoiceStep: 'Facturación',
}

export const BUY_EXPEDIENT_STEPS = {
  PERSONAL_DATA: 'personalDataStep',
  DOCUMENTS: 'documentsStep',
  INVOICE: 'invoiceStep',
}

export const BUY_EXPEDIENT_FILES = {
  PERSONALID_FRONT: 'personalIdFront',
  PERSONALID_BACK: 'personalIdBack',
  PROOF_RESIDENCY: 'proofResidency',
  PROOF_TAX_RESIDENCY: 'proofTaxAddress',
}

export const EASY_STEPS = {
  SELLER_INFORMATION_STEP: 'sellerInformationStep',
  CAR_INFORMATION_STEP: 'carInformationStep',
  SCHEDULE_STEP: 'scheduleStep',
}

export const SELLERS_TYPE = {
  CLIKAUTO: 'ClikAuto',
  EASY_AGENCY: 'CilkAuto Easy (Agencia)',
  EASY_PARTICULAR: 'CilkAuto Easy (Particular)',
}

export const EASY_TYPE = {
  PARTICULAR: 'particular',
  AGENCIA: 'company',
  PROXIMO: 'proximo',
}

export const PARTICULAR_TYPE = {
  PHYSICAL: 'physical',
  VIRTUAL: 'virtual',
}

export const VDP_TYPE = {
  CLIKAUTO: 'clikauto',
  PARTICULAR: 'particular',
  AGENCIA: 'agencia',
  PROXIMO: 'proximo',
  PARTNER: 'partner',
}

export const SRP_TYPE = {
  NORMAL: 'normal',
  DRAFT: 'draf',
  FILTERS: 'filtros',
  VIRTUALS: 'virtuales',
  BONUS: 'bonus',
}

export const CATEGORIES_IMAGE = {
  EXTERIOR: 'Exterior',
  INTERIOR: 'Interior',
  DETALLES: 'Detalles',
}

export const AUCTIONS_SECTIONS = {
  SRP: 'SRP',
  WINNER: 'WINNER',
  STATUS: 'STATUS',
}

export const OFFERS_TYPE = {
  TAKE: 'take',
  EASY: 'easy',
  DIRECT_EASY: 'direct_easy',
}

export const DIG_EXP_FIELDS = {
  INE_FRONT: 'ine_front',
  INE_BACK: 'ine_back',
  PASSPORT_FRONT: 'passport_front',
  CIRCULATION_CARD_FRONT: 'circulation_card_front',
  CIRCULATION_CARD_BACK: 'circulation_card_back',
  INVOICE_FRONT: 'invoice_front',
  INVOICE_BACK: 'invoice_back',
  PASSPORT_OLD_FRONT: 'passport_old_front',
  INE_OLD_FRONT: 'previous_owner_ine_front',
  INE_OLD_BACK: 'previous_owner_ine_back',
  INVOICE_OLD_FRONT: 'previous_invoice_1_front',
  INVOICE_OLD_BACK: 'previous_invoice_1_back',
  INVOICE_OLD2_FRONT: 'previous_invoice_2_front',
  INVOICE_OLD2_BACK: 'previous_invoice_2_back',
}

export const OFFERS_TYPES = {
  DIRECT_SALLING: 'directSalling',
  EASY_ESTIMATED: 'easyEstimated',
  EASY_NO_ESTIMATED: 'easyNoEstimated',
}

export const MECHANIC_CARD_TYPES = {
  BUENO: 'bueno',
  REGULAR: 'regular',
  MALO: 'malo',
}

export const VDP_TYPES_PATH = {
  CLIKAUTO: 'vdpclikauto',
  PARTICULAR: 'vdpeasy',
  AGENCIA: 'vdpagencia',
}
