import envs from 'envs'
import React, { createContext, useEffect, useState } from 'react'

const ActionCableContext = createContext()

const ActionCableProvider = ({ children }) => {
  const [cableApp, setCableApp] = useState({})

  const loadConsumer = async () => {
    const { createConsumer } = await import('@rails/actioncable')
    return createConsumer
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadConsumer().then((createConsumer) => {
        setCableApp({
          cable: createConsumer(envs?.wsApiRoot),
        })
      })
    }
  }, [])

  return (
    (
      <ActionCableContext.Provider value={cableApp.cable}>
        {children}
      </ActionCableContext.Provider>
    )
  )
}

export { ActionCableContext, ActionCableProvider }
