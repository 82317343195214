import React, {
  useEffect, useState, useMemo,
} from 'react'
import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { Router, useRouter } from 'next/router'
import { IntlProvider } from 'react-intl'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import esLocale from 'date-fns/locale/es'
import { wrapper } from 'redux/store'
import { PagePropsContext } from 'hooks/usePageProps'
import dynamic from 'next/dynamic'
import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import createEmotionCache from 'createEmotionCache'
import themeV2 from 'themes/Clikauto/theme-v2'
import stylesV2 from 'themes/Clikauto/styles-v2'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'
import 'globals.css'
import LinearProgress from '@mui/material/LinearProgress'
import { ModalProvider } from 'components/shared/Modal/ModalProvider'
import { Modal } from 'components/shared/Modal'
import EventEmitter from 'services/EventEmitter'
import { getPhonesByChannel } from 'utils/universal/getPhonesByChannel'
import { useSelector } from 'react-redux'
import { isIndexedPageSRP } from 'utils/vehicles'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import { ActionCableProvider } from 'providers/ActionCableProvider'

const DynamicAnalytics = dynamic(
  () => import('components/seo/Analytics'),
  { ssr: false },
)

const clientSideEmotionCache = createEmotionCache()

const MyApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const {
    messages,
    canonicalUrl: canonicalUrlProp,
    phones,
    showCanonical: showCanonicalProp,
  } = pageProps
  const { locale, asPath, query } = useRouter()
  const [queryClient] = useState(() => new QueryClient())
  const [loading, setLoading] = useState(false)
  const [showCanonical, setShowCanonical] = useState(showCanonicalProp)
  const [canonicalUrl, setCanonicalUrl] = useState(canonicalUrlProp)
  const { filters } = useSelector((s) => s.search)

  const finalPhones = useMemo(() => getPhonesByChannel(query, phones), [query, phones])

  useEffect(() => {
    const url = (`https://clikauto.com${asPath === '/' ? '' : asPath}`).split('?')[0]
    if (asPath === '/autos-seminuevos/bonus-true') {
      setCanonicalUrl('https://clikauto.com/autos-seminuevos')
    } else {
      setCanonicalUrl(url)
    }
  }, [asPath])

  useNonInitialEffect(() => {
    setShowCanonical(isIndexedPageSRP(filters) || !`${asPath}`.includes('/auto-seminuevo/'))
  }, [filters, asPath])

  useEffect(() => {
    const start = () => {
      setLoading(true)
    }

    const end = () => {
      setLoading(false)
    }

    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
      EventEmitter.removeAllListeners()
    }
  }, [])

  return (
    <SessionProvider>
      <Head>
        {showCanonical && (
          <link
            rel="canonical"
            href={canonicalUrl}
          />
        )}
      </Head>
      <CacheProvider value={emotionCache}>
        <ActionCableProvider>
          <ThemeProvider theme={createTheme(themeV2)}>
            {loading && (
              <LinearProgress
                color="primary"
                sx={{
                  position: 'fixed', top: 0, width: '100%', zIndex: 300,
                }}
              />
            )}
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <PagePropsContext.Provider value={{ ...pageProps, ...finalPhones }}>
                  <IntlProvider
                    locale={locale}
                    messages={messages}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={esLocale}
                    >
                      <ModalProvider>
                        <Modal />
                        <Component />

                        {process.env.NODE_ENV === 'production' && (
                          <DynamicAnalytics />
                        )}

                      </ModalProvider>
                    </LocalizationProvider>
                  </IntlProvider>
                </PagePropsContext.Provider>
              </Hydrate>
            </QueryClientProvider>
            <CssBaseline />
            <GlobalStyles styles={stylesV2} />
          </ThemeProvider>
        </ActionCableProvider>
      </CacheProvider>
    </SessionProvider>
  )
}

export default wrapper.withRedux(MyApp)
