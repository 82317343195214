import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import { titleCaps } from 'utils/universal'
import {
  EASY_TYPE, PARTICULAR_TYPE, SRP_TYPE, VDP_TYPE,
  VDP_TYPES_PATH,
} from 'enums'
import { externalBranches } from 'vars'

export const getVehicleMeta = (vehicle, withState = false) => {
  if (!vehicle) return // TODO HANDLE EEXPTION TO SNETRY
  let meta
  if (withState) {
    meta = String(
      `${vehicle?.maker}-${vehicle?.model}-${vehicle?.year}-${vehicle.state}`,
    )
  } else {
    meta = String(
      `${vehicle?.maker}-${vehicle?.model}-${vehicle?.year}`,
    )
  }

  return kebabCase(meta)
}

export const getVehicleType = (vehicle) => {
  const isEasy = vehicle?.easy || vehicle?.consignacion
  const states = ['edo. mex', 'CDMX - Agencias externas']

  if (vehicle?.authorizedSeller
    && vehicle?.dealerId !== 42
    && vehicle?.dealerId !== 44
    && !(isEasy)
    && vehicle?.status !== 'ONGOING') {
    return VDP_TYPE.CLIKAUTO
  }

  if (
    (
      isEasy
      && vehicle?.ownerType === EASY_TYPE.AGENCIA
      && vehicle?.holdingType === PARTICULAR_TYPE.VIRTUAL
      && (states?.includes(vehicle?.seller?.name)
        || states?.includes(vehicle?.seller?.branch?.name))
    )) {
    return VDP_TYPE.PARTNER
  }

  if (isEasy
    && vehicle?.ownerType === EASY_TYPE.AGENCIA
    && vehicle?.holdingType === PARTICULAR_TYPE.VIRTUAL
    && !states?.includes(vehicle?.seller?.name)) {
    return VDP_TYPE.AGENCIA
  }

  if (vehicle?.status === 'ONGOING') {
    return VDP_TYPE.PROXIMO
  }

  if ((isEasy) && vehicle?.ownerType === EASY_TYPE.PARTICULAR) {
    return VDP_TYPE.PARTICULAR
  }

  return VDP_TYPE.PROXIMO
}

export const getVehicleLink = (vehicle, prefix = 'auto-seminuevo') => {
  if (!vehicle) return // TODO HANDLE EEXPTION TO SNETRY
  const meta = getVehicleMeta(vehicle)
  const vehicleType = getVehicleType(vehicle)
  if (vehicleType === VDP_TYPE.AGENCIA || vehicleType === VDP_TYPE.PARTNER) {
    return `/${prefix}/${meta}/${vehicle.id}/vdpagencia/${vehicle?.dealerId}`.replace(/ /g, '_').toLowerCase()
  } if (vehicleType === VDP_TYPE.PARTICULAR) {
    return `/${prefix}/${meta}/${vehicle.id}/vdpeasy`.replace(/ /g, '_').toLowerCase()
  } if (vehicleType === VDP_TYPE.PROXIMO) {
    return `/${prefix}/${meta}/${vehicle.id}/proximo`.replace(/ /g, '_').toLowerCase()
  }

  return `/${prefix}/${meta}/${vehicle.id}/vdpclikauto`.replace(/ /g, '_').toLowerCase()
}

export const getVehicleAuctionLink = (vehicle, prefix = 'subastas/auction', section = 'srp') => {
  if (!vehicle) return // TODO HANDLE EEXPTION TO SNETRY
  const vehicleId = section === 'srp' ? vehicle?.auction?.id : vehicle?.auction?.auctionId
  return `/${prefix}/${vehicleId}`.replace(/ /g, '_').toLowerCase()
}

export const getVehicleTitle = (vehicle) => {
  return titleCaps(`${vehicle?.maker} ${vehicle?.model} ${vehicle?.year}`)
}

export const getVehicleStatus = (vehicle) => {
  if (!vehicle) return ''

  if (`${vehicle.status}`.toLowerCase().includes('hold online')) {
    return 'HOLD_ONLINE'
  }
  if (`${vehicle.status}`.toLowerCase().includes('hold')) {
    return 'HOLD'
  }
  if (`${vehicle.status}`.toLowerCase().includes('available')) {
    return 'AVAILABLE'
  }
  if (`${vehicle.status}`.toLowerCase().includes('ongoing')) {
    return 'ONGOING'
  }
  if (`${vehicle.status}`.toLowerCase().includes('archived')) {
    return 'ARCHIVED'
  }
  if (`${vehicle.status}`.toLowerCase().includes('discount')) {
    return 'WITH_DISCOUNT'
  }
  if (`${vehicle.status}`.toLowerCase().includes('draft')) {
    return 'DRAFT'
  }
}

export const getVehicleDiscounts = (vehicle = {}) => {
  if (!vehicle) return {}
  const discount = get(vehicle, 'discount', {})
  const onlineDiscount = discount
  const titleDiscount = get(onlineDiscount, 'title', '')
  const amountDiscount = get(onlineDiscount, 'amount', 0)
  const operation = get(onlineDiscount, 'operation', 'none')
  const category = get(onlineDiscount, 'category', 'Descuento')
  const endPromotionDate = get(onlineDiscount, 'end_date', null)

  const hasDiscount = parseInt(amountDiscount) > 0 && operation !== 'suma'
  let precioTachado = 0
  let { price } = vehicle

  if (hasDiscount) {
    if (operation === 'resta') {
      price = Number(price) - Number(amountDiscount)
      precioTachado = Number(vehicle?.price)
    } else if (operation === 'adjusment') {
      precioTachado = Number(vehicle?.price) + Number(amountDiscount)
    }
  }
  const finalPrice = hasDiscount ? price : Number(vehicle.price)
  return {
    onlineDiscount,
    titleDiscount,
    amountDiscount,
    operation,
    category,
    hasDiscount,
    price,
    finalPrice,
    precioTachado,
    endPromotionDate,
  }
}

export const getCanonicalUrl = (path) => {
  const canonicalsUrls = {
    '/auto-seminuevo/audi-a-1-2020/11956': '/auto-seminuevo/audi-a-1-2020/11736',
    '/auto-seminuevo/audi-a-3-2018/12122': '/auto-seminuevo/audi-a-3-2018/11731',
    '/auto-seminuevo/audi-q-2-2019/12018': '/auto-seminuevo/audi-q-2-2019/11775',
    '/auto-seminuevo/buick-encore-2018/9035': '/auto-seminuevo/buick-encore-2018/10433',
    '/auto-seminuevo/chevrolet-onix-2022/9839': '/auto-seminuevo/chevrolet-onix-2022/9678',
    '/auto-seminuevo/chevrolet-onix-2022/9840': '/auto-seminuevo/chevrolet-onix-2022/9678',
    '/auto-seminuevo/chevrolet-onix-2022/9679': '/auto-seminuevo/chevrolet-onix-2022/9678',
    '/auto-seminuevo/chevrolet-onix-2022/9680': '/auto-seminuevo/chevrolet-onix-2022/9678',
    '/auto-seminuevo/chevrolet-trax-2018/9511': '/auto-seminuevo/chevrolet-trax-2018/8915',
    '/auto-seminuevo/dodge-journey-2018/9522': '/auto-seminuevo/dodge-journey-2018/10240',
    '/auto-seminuevo/honda-city-2018/11482': '/auto-seminuevo/honda-city-2018/10978',
    '/auto-seminuevo/honda-hr-v-2018/9073': '/auto-seminuevo/honda-hr-v-2018/11959',
    '/auto-seminuevo/hyundai-tucson-2017/9854': '/auto-seminuevo/hyundai-tucson-2017/8871',
    '/auto-seminuevo/kia-sedona-2020/11262': '/auto-seminuevo/kia-sedona-2020/11605',
    '/auto-seminuevo/kia-sportage-2017/11709': '/auto-seminuevo/kia-sportage-2017/9993',
    '/auto-seminuevo/mazda-cx-3-2017/12172': '/auto-seminuevo/mazda-cx-3-2017/11118',
    '/auto-seminuevo/mazda-cx-5-2018/11594': '/auto-seminuevo/mazda-cx-5-2018/11622',
    '/auto-seminuevo/mazda-mazda-3-2018/9552': '/auto-seminuevo/mazda-mazda-3-2018/9559',
    '/auto-seminuevo/mercedes-benz-clase-glc-300-2018/9988': '/auto-seminuevo/mercedes-benz-clase-glc-300-2018/11892',
    '/auto-seminuevo/nissan-np-300-frontier-2018/12021': '/auto-seminuevo/nissan-np-300-frontier-2018/4682',
    '/auto-seminuevo/renault-koleos-2020/11484': '/auto-seminuevo/renault-koleos-2020/10562',
    '/auto-seminuevo/volkswagen-beetle-2018/11233': '/auto-seminuevo/volkswagen-beetle-2018/11686',
  }
  return (path in canonicalsUrls) ? canonicalsUrls[path] : path
}

export const getVehicleTotal = (price = 0, list = []) => {
  let total = price
  list.forEach((item) => {
    total = item.operation === '-' ? parseInt(total) - parseInt(item.price || 0)
      : parseInt(total) + parseInt(item.price || 0)
  })
  return total
}

export const formatLargeTransmission = (value = '') => {
  const val = String(value).toLowerCase()
  if (val.includes('aut')
    || val === 'steptronic'
    || val === 'dsg'
    || val === 's tronic'
    || val === 'pdk'
    || val === 'edc'
    || val === 'twinami'
    || val === 'tct'
    || val === 'powershift'
    || val === 'cvt'
  ) {
    return 'Automático'
  }
  if (val.includes('man')) {
    return 'Manual'
  }
  return null
}

export const getCanonicalVDP = (vehicle) => {
  if (!vehicle) return ''
  const meta = String(
    `/autos-seminuevos/marca-${vehicle?.maker}/modelo-${vehicle?.model}`,
  ).toLowerCase()
  return meta
}

// eslint-disable-next-line no-unused-vars
export const getCanonicalSRPUrl = (filters) => {
  // console.log(filters)
  return ''
}

export const hasOnlyMakerModelYearFilterSelected = (filters) => {
  if (isEmpty(filters)) return false
  return Boolean(
    filters?.maker
    && filters?.model
    && filters?.year
    && Object.keys(pickBy(filters)).length === 3,
  )
}

export const hasOnlyBodyFilterSelected = (filters) => {
  return Boolean(
    filters?.body
    && Object.keys(pickBy(filters)).length === 1,
  )
}

export const hasOnlyMakerFilterSelected = (filters) => {
  return Boolean(
    filters?.maker
    && Object.keys(pickBy(filters)).length === 1,
  )
}

export const getImageMeta = (vehicle, attr = 'alt', type = 'default', bodyType = '', maker = '', finalPrice = 0) => {
  const yearText = `${vehicle?.maker} ${vehicle?.model} ${vehicle?.year}`
  const bodyText = `Autos ${bodyType} Seminuevos`
  const makerText = `${maker} Seminuevos`
  const filterVehicle = {
    alt: {
      year: yearText,
      body: bodyText,
      marca: makerText,
      default: `Autos seminuevos ${finalPrice}`,
    },
    title: {
      year: yearText,
      body: bodyText,
      marca: makerText,
      default: `Autos seminuevos ${finalPrice}`,
    },
  }
  return filterVehicle[attr][type]
}

export const getJsonLDVDPData = (vehicle, temporaryImageDomain, finalPrice = 0) => {
  const firstImage = vehicle?.images.length > 0 ? vehicle?.images[0] : ''
  return {
    name: `${getVehicleTitle(vehicle)} seminuevo`,
    brand: `${vehicle?.maker}`,
    description: `${getVehicleTitle(vehicle)} Seminuevo. Llévatelo a crédito, con hasta 60 mensualidades y 20% de enganche o de contado. 1 Año garantía sin costo`,
    keywords: [`${getVehicleTitle(vehicle)} seminuevo`, `${getVehicleTitle(vehicle)}`],
    model: `${vehicle?.model}`,
    modelDate: `${vehicle?.year}`,
    seatingCapacity: `${vehicle?.cesviPassengers} Pasajeros`,
    vehicleInteriorType: `${vehicle?.cesviInteriorMaterial}`,
    bodyType: vehicle?.body,
    image: `${firstImage
      ? `${temporaryImageDomain}/800x500max/filters:format(webp)/${firstImage.filename}`
      : 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/No_foto_AutoSRP.webp'}`, // TODO: agregar la img default
    mileageFromOdometer: {
      '@type': 'QuantitativeValue',
      value: `${vehicle?.mileage} `,
      unitCode: 'KM',
    },
    vehicleTransmission: `${vehicle?.cesviTransmission} `,
    fuelType: `${vehicle?.cesviFuel} `,
    color: `${vehicle?.exteriorColor} `,
    offers: {
      '@type': 'Offer',
      price: finalPrice,
      priceCurrency: 'MXN',
      itemCondition: 'Seminuevo',
      availability: 'Disponible',
      seller: {
        '@type': 'Organization',
        name: 'ClikAuto',
        logo: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/logo.webp',
        image: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/compra-un-auto-seminuevo-con-garantia-o-vende-tu-auto_en_ClikAuto-1.webp',
        description: 'Compra, vende o cambia tu auto rápido, seguro y al mejor precio',
        url: 'https://clikauto.com',
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '5589571916',
          contactType: 'Servicio al cliente',
        },
      },
    },
  }
}

export const getSRPSchemaToJsonLD = (vehicles, temporaryImageDomain) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    itemListElement: [
      vehicles?.map((vehicle, index) => {
        const firstImage = vehicle?.images.length > 0 ? vehicle?.images[0] : ''
        return (
          {
            '@type': 'ListItem',
            position: `${index + 1}`,
            id: `${vehicle.id}`,
            item: {
              '@type': 'Car',
              url: `https://clikauto.com${getVehicleLink(vehicle)}`,
              name: `${vehicle?.maker} ${vehicle?.model} ${vehicle?.year} seminuevo`,
              brand: `${vehicle?.maker}`,
              description: `${vehicle?.maker} ${vehicle?.model} ${vehicle?.year} seminuevo. Llévatelo a crédito, con hasta 60 mensualidades y 20% de enganche o de contado. 1 Año garantía sin costo`,
              keywords: [`${vehicle?.maker} ${vehicle?.model} ${vehicle?.year} seminuevo`, `${vehicle?.maker} ${vehicle?.model} ${vehicle?.year}`],
              model: `${vehicle?.model}`,
              modelDate: `${vehicle?.year}`,
              seatingCapacity: `${vehicle?.passengers}`,
              vehicleInteriorType: `${vehicle?.cesviInteriorMaterial}`,
              bodyType: `${vehicle?.body}`,
              image: `${firstImage
                ? `${temporaryImageDomain}/800x500max/filters:format(webp)/${firstImage?.filename}`
                : 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/No_foto_AutoSRP.webp'}`,
              mileageFromOdometer: {
                '@type': 'QuantitativeValue',
                value: `${vehicle?.mileage}`,
                unitCode: 'KM',
              },
            },
            vehicleTransmission: `${vehicle?.transmission}`,
            fuelType: `${vehicle?.fuelType}`,
            color: `${vehicle?.exteriorColor}`,
            offers: {
              '@type': 'Offer',
              price: `$${vehicle?.price}`,
              priceCurrency: 'MXN',
              itemCondition: 'Seminuevo',
              availability: 'Disponible',
              seller: {
                '@type': 'Organization',
                name: 'ClikAuto',
                logo: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/logo.webp',
                image: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/compra-un-auto-seminuevo-con-garantia-o-vende-tu-auto_en_ClikAuto-1.webp',
                description: 'Compra, vende o cambia tu auto rápido, seguro y al mejor precio',
                url: 'https://clikauto.com',
                contactPoint: {
                  '@type': 'ContactPoint',
                  telephone: '5589571916',
                  contactType: 'Servicio al cliente',
                },
              },
            },
          })
      })],
  }
}

export const hasSecondMakerOrModel = (filters) => {
  return filters?.maker?.split('_')?.length >= 2 || filters?.model?.split('_')?.length >= 2
}

export const hasSecondYear = (filters) => {
  return filters?.year?.split('_').length >= 2
}

export const hasTransmission = (filters) => {
  return filters?.transmission?.split('_').length >= 1
}

export const hasSecondBodyType = (filters) => {
  return filters?.body?.split('_').length >= 2
}

export const isIndexedPageSRP = (filters, isDraftEasy = false) => {
  if (isEmpty(pickBy(filters)) && isDraftEasy === false) return true
  return !(
    hasSecondMakerOrModel(filters)
    || hasSecondYear(filters)
    || hasTransmission(filters)
    || hasSecondBodyType(filters)
  ) || isDraftEasy
}

export const getRobotsContentSRP = (filters, isDraftEasy = false, srpType = SRP_TYPE.NORMAL) => {
  if (filters?.bonus) return 'noindex,follow'
  if (isIndexedPageSRP(filters, isDraftEasy) && srpType === SRP_TYPE.NORMAL && !(filters?.owner_type || filters?.holding_type)) return 'index,follow'
  return 'noindex,nofollow'
}

export const getRobotsContentVDP = () => {
  // if (getVehicleStatus(vehicle) === 'ARCHIVED') return 'noindex, nofollow'
  return 'index,follow'
}

export const getJsonLdBreadcrumbs = (maker, model, year) => {
  const basePath = process.env.NEXT_PUBLIC_BASE_URL
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [{
      '@type': 'ListItem',
      position: 1,
      name: 'marca',
      item: `${basePath}/autos-seminuevos/marca-${maker}`,
    }, {
      '@type': 'ListItem',
      position: 2,
      name: 'modelo',
      item: `${basePath}/autos-seminuevos/marca-${maker}/modelo-${model}`,
    }, {
      '@type': 'ListItem',
      position: 3,
      name: 'antiguedad',
      item: `${basePath}/autos-seminuevos/marca-${maker}/modelo-${model}/antiguedad-${year}`,
    }],
  }
}

export const getEasyParticularType = (vehicle) => {
  return (vehicle?.ownerType === EASY_TYPE.PARTICULAR
    && vehicle?.holdingType === PARTICULAR_TYPE.PHYSICAL)
    ? PARTICULAR_TYPE.PHYSICAL : PARTICULAR_TYPE.VIRTUAL
}

export const getBranchIdByName = (sellerName) => {
  if (process.env.NEXT_PUBLIC_BASE_URL === 'https://clikauto.com') {
    return `${sellerName}`.includes('Nápoles') ? 7 : 1
  }
  return `${sellerName}`.includes('Nápoles') ? 10 : 4
}

export const getBranchName = (sellerName) => {
  let brancNew = sellerName
  if (`${sellerName}`.toLowerCase().includes('nápoles') || `${sellerName}`.toLowerCase().includes('napoles')) {
    brancNew = 'ClikAuto Nápoles'
  } else if (`${sellerName}`.toLowerCase().includes('satélite') || `${sellerName}`.toLowerCase().includes('satelite')) {
    brancNew = 'ClikAuto Satelite'
  } else if (`${sellerName}`.toLowerCase().includes('sur')) {
    brancNew = 'ClikAuto Gran Sur'
  } else if (`${sellerName}`.toLowerCase().includes('verdes')) {
    brancNew = 'ClikAuto Lomas Verdes'
  }
  return brancNew
}

export const isValidYear = (year) => {
  const maxYears = 10
  const minYear = new Date().getFullYear() - maxYears
  return year >= minYear
}

export const showCredit = (vehicle) => {
  const vehicleType = getVehicleType(vehicle)
  return ((vehicleType === 'clikauto')
    || (
      vehicleType === 'particular' && (
        vehicle?.acceptFinancing || vehicle?.vehicleInspection?.acceptFinancing)
    )
    || (vehicleType === 'agencia' && isValidYear(vehicle?.year)))
}

export const isQroBranch = (branchId) => {
  const juriquillaAndLaCapillaBranches = [35, 36, 13, 14]
  return juriquillaAndLaCapillaBranches?.includes(branchId)
}

export const getNextUrl = (vehicleId) => [
  `me-interesa/vehiculo/${vehicleId}/conocelo/paso1`,
  `me-interesa/vehiculo/${vehicleId}/apartado`,
  `mis/mensajes/crear/${vehicleId}`,
  `me-interesa/vehiculo/${vehicleId}/conocelo/paso1`,
  `me-interesa/vehiculo/${vehicleId}/conocelo/paso1`,
].map((url) => `${process.env.NEXT_PUBLIC_OLD_APP_URL}/${url}`)

export const getVdpTypesPath = (vdpType) => {
  if (vdpType === VDP_TYPE.PARTICULAR) {
    return VDP_TYPES_PATH.PARTICULAR
  }
  if (vdpType === VDP_TYPE.AGENCIA) {
    return VDP_TYPES_PATH.AGENCIA
  }
  return VDP_TYPES_PATH.CLIKAUTO
}

export const hasCompleteFeaturedSpecs = (featured) => {
  return Boolean(featured?.fuelType)
    || Boolean(featured?.transmissionSpeed)
    || Boolean(featured?.transmissionType)
    || Boolean(featured?.engineDescription)
    || Boolean(featured?.transmissionTraction)
    || Boolean(featured?.engineHorsepower)
    || Boolean(featured?.interiorCapacity)
    || Boolean(featured?.fuelEfficiencyCity)
}

export const getCharacteristicsVehicle = (characteristicsObj) => {
  const characteristics = {
    featured: {
      fuelType: characteristicsObj?.fuel_type,
      transmissionSpeed: characteristicsObj?.transmission_speeds,
      transmissionType: characteristicsObj?.transmission_type,
      engineDescription: characteristicsObj?.engine_description,
      transmissionTraction: characteristicsObj?.transmission_traction,
      engineHorsepower: characteristicsObj?.engine_horsepower,
      interiorCapacity: characteristicsObj?.interior_capacity,
      fuelEfficiencyCity: characteristicsObj?.fuel_efficiency_city,
    },
    fuel: characteristicsObj?.fuel
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    motor: characteristicsObj?.motor
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    transmission: characteristicsObj?.transmission
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    suspension: characteristicsObj?.suspension
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    exterior: characteristicsObj?.exterior
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    dimensions: characteristicsObj?.dimensions
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    interior: characteristicsObj?.interior
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    confort: characteristicsObj?.confort
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    driver_assistance: characteristicsObj?.driver_assistance
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
    infotainment: characteristicsObj?.infotainment
      ?.filter((i) => `${i.content}`.trim() !== null)
      ?.filter((i) => !`${i.content}`.trim().toLowerCase()?.includes('no disponible')),
  }
  return characteristics
}

export const getExternalBranchWhatsapp = (branchId) => {
  const externalBranchSelected = externalBranches?.find((i) => i?.id === branchId)
  return externalBranchSelected ? `52${externalBranchSelected?.whatsapp}` : null
}

export const getBreadcrumbs = ({ maker, model, year }) => {
  return [
    {
      label: { id: 'HOME_LINK_LABEL' },
      href: { id: 'ROUTE_HOME' },
    },
    {
      label: { id: 'SEARCH_LINK_LABEL' },
      href: { id: 'ROUTE_SEARCH' },
    },
    {
      label: {
        id: 'VDP_LINK_MAKER_LABEL',
        values: {
          maker: titleCaps(maker),
        },
      },
      href: { id: 'ROUTE_SEARCH_MAKER', values: { maker: `${maker}`.toLowerCase() } },
    },
    {
      label: {
        id: 'VDP_LINK_MODEL_LABEL',
        values: {
          model: titleCaps(model),
        },
      },
      href: { id: 'ROUTE_SEARCH_MODEL', values: { maker: `${maker}`.toLowerCase(), model: `${model}`.toLowerCase() } },
    },
    {
      label: {
        id: 'VDP_LINK_YEAR_LABEL',
        values: {
          year,
        },
      },
      href: { id: 'ROUTE_SEARCH_YEAR', values: { year, maker: `${maker}`.toLowerCase(), model: `${model}`.toLowerCase() } },
    },
  ]
}

export const getDescriptionSchedule = (vehicleType) => {
  if (vehicleType === VDP_TYPE.CLIKAUTO) {
    return 'VDP_CLIKAUTO_SCHEDULE_DESCRIPTION'
  }
  if (vehicleType === VDP_TYPE.PARTICULAR) {
    return 'VDP_EASY_SCHEDULE_DESCRIPTION'
  }
  if (vehicleType === VDP_TYPE.AGENCIA || vehicleType === VDP_TYPE.PARTNER) {
    return 'VDP_AGENCY_SCHEDULE_DESCRIPTION'
  }
  return 'VDP_CLIKAUTO_SCHEDULE_DESCRIPTION'
}

export const getAddressTextSchedule = (vehicle, vehicleType, branch) => {
  if (vehicleType === VDP_TYPE.PARTNER) {
    const address = `${vehicle?.seller?.branch?.fullAddress?.municipality_name}, ${vehicle?.seller?.branch?.fullAddress?.state_name}`
    return address
  }
  return `${branch?.address}`
}
