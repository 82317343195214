import isEmpty from 'lodash/isEmpty'
import {
  salesPhone, shoppingPhone, whatsapp, whatsappVTA,
} from 'vars'

export const getPhonesByChannel = (query, phones = []) => {
  let finalPhones = []
  const referrer = typeof window !== 'undefined' ? window.document.referrer : ''
  const getQuery = { ...query }
  if (!phones) return []
  if (isEmpty(getQuery) && !isEmpty(referrer)) {
    if (referrer.includes('facebook')) {
      getQuery.utm_source = 'facebook'
      getQuery.utm_medium = ''
    }
    if (referrer.includes('google')) {
      getQuery.utm_source = 'google'
      getQuery.utm_medium = ''
    }
  }
  if ((isEmpty(getQuery) && isEmpty(referrer)) || !('utm_source' in getQuery)) {
    getQuery.utm_source = 'default'
    getQuery.utm_medium = ''
  }

  finalPhones = phones.find(
    (item) => (item.utm_source === getQuery?.utm_source
      && item.utm_medium === getQuery?.utm_medium),
  )

  // if (finalPhones?.length === 0) {
  //   finalPhones = phones.find(
  //     (item) => (item.utm_source === 'default'
  //       && item.utm_medium === ''),
  //   )
  // }

  return ({
    fuente: finalPhones?.fuente,
    whatsapp: finalPhones
      ? finalPhones?.num_whatsapp_compras
      : whatsapp,
    whatsappVTA: finalPhones
      ? finalPhones?.num_whatsapp_ventas
      : whatsappVTA,
    salesPhone: finalPhones
      ? finalPhones?.num_llamada_ventas
      : salesPhone,
    shoppingPhone: finalPhones
      ? finalPhones?.num_llamada_compras
      : shoppingPhone,
  })
}
